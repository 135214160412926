 .flexImgLogo {
    display: flex;
    justify-content: space-between;
    /* margin-top: 60px; */
 }

 .header_logo {
    padding: 0 !;
 }

 /* .mobile_heigth{
   
} */

 .img-fluid {
    max-width: 100%;
    height: auto;
    /* width: 1181px; */
 }
 .css-1vdz5nh-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline{
   border-width: 0 !important;
 }

 @media only screen and (max-width: 700px) {

    .header_logo {
       height: 720px;
    }


 }


 
 @media only screen and (max-width: 500px) {
    .container_header_logo {
       position: relative;
    }

    .mobile_height {
       width: 100%;
       height: 623px !important;
    }

    .header_logo_height {
      width: 100%;
    height: 100px;
    margin-top: 34px;

    }
 }

 /* @media only screen and (max-width: 800px) {
   .mobile_height {
      height: 43rem;
   } */

 @media only screen and (max-width: 750px) {
    /* .mobile_height {
       height: 43rem;
    } */

    .header_logo {

       height: 655px;
    }

    .flexImgLogo {

       /* min-width: 352px; */
       height: 263.38px;

       margin-top: 0;

    }

    .img-fluid {

       margin-left: 20px;
       width: 100%;
       height: 100%;

    }

    [dir='rtl'] .img-fluid {

       margin-right: 20px;


    }

 }

 @media only screen and (min-width: 1000px) {
    .flex {
       max-width: 39%;
    }

 }

 @media only screen and (max-width: 1000px) {
    .flexImgLogo {
       display: flex;
       flex-direction: column;
       align-items: flex-end;
    }

    .divHead {
       margin-top: 0;
    }



 }

 @media only screen and (max-width: 1030px) {}

 [dir="rtl"] .img-fluid {
    transform: scaleX(-1);
 }

 .deliver {
    /* position: absolute; */
    width: 885px;
    height: 590px;
    left: 65.71px;
    top: 95px;
 }

 .circle {

    margin-left: 111.71px;
    margin-top: 64px;

 }

 .illustration {

    position: absolute;
    top: 50.6%;
    bottom: -41.03%;
    left: 41.03%;


 }