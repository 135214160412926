.h1Step {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 37px;
    line-height: 54px;
    text-align: inherit;
    letter-spacing: -2px;

    color: #0D152E;
}

.css-ahj2mt-MuiTypography-root {
    font-family: 'Heebo' !important;
}

.full_width_checkboxFlex {
    width: 100%;
    /* padding-left: 15px;
    padding-right: 15px; */
}

.checkboxFlex {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    align-content: flex-end;
    max-width: 545px;
    height: 63px;
    max-width: 545px;
    
    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}


[dir='rtl'] .checkboxFlex {
    align-content: flex-start;
}


.FormLabelCheckBox {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 19px 20px;
    gap: 15px;
    width: 30%;
    height: 63px;
    background: #F2F5FF;
    border: 1px solid #E1E1E8;
    border-radius: 4px;
    box-sizing: border-box;
    justify-content:flex-start;
   
    height: 63px;
    margin-left: 0px !important;
    margin-right: 0px !important;
    background: #F2F5FF;
    border: 1px solid #E1E1E8;
    border-radius: 4px;


}


.h1Summary {
    width: 184px;
    height: 54px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 37px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -2px;

    color: #0D152E;
}

.pStep2 {
    width: 433px;
    height: 23px;

    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;


    /* text-align: center; */
    letter-spacing: -0.66px;

    color: #81838C;

}

@media only screen and (max-width: 650px) {
    .h1Step {
        text-align: center;
    }

    .pStep2 {
        text-align: center;
    }


    .FormLabelCheckBox {
        width: 24%;

        margin-left: 0 !important;
        gap: 0 !important;
        margin-right: 0 !important;
    }

    /* [dir='rtl']  .FormLabelCheckBox {
          justify-content: flex-end;

    } */

    .checkboxFlex {
        max-width: 500px;
        align-content: center;
        /* width: 98%; */
        margin: 0;
        /* gap: 30px; */

    }

    [dir='rtl'] .checkboxFlex {
        align-content: center;
        justify-content: center;

    }

    .MuiFormControl-root {
        width: 400px;

    }

    .slidecontainer {
        width: 400px !important;
    }

    .h1Summary {
        text-align: center !important;
    }


}

@media only screen and (max-width: 500px) {
    .MuiFormControl-root {
        width: 100% !important;
    }

    .full_width_checkboxFlex {
        padding: unset;
    }

    .checkboxFlex {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 30px;
        width: 100%;
        /* min-width: 336px; */
        height: 249px;
    }

    .FormLabelCheckBox {

        justify-content: flex-start;
        width: 100%;
        margin-left: -11px !important;
        margin-right: -11px !important;
    }

    .h1Step {
        height: 34px;
        font-size: 26px;
        /* line-height: 34px; */
        /* text-align: center; */
        letter-spacing: -1.24px;
    }
}