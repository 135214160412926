


.mobile_width_detailofpackage {
  max-width: 516px;
  width: 100%;
}


.mobile_details_height {
  background: #F1F1F6;
  height: 809px;
}




.marginBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  width: 100%;
  height: 62px;
  background: #583DFF;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 17px !important;
  line-height: 25px !important;
  text-align: center;
  color: #FFFFFF;

}

@media only screen and (min-width: 1150px) {
  [dir="ltr"] .details::before {
    content: '';
    position: absolute;
    width: 149.87px;
    height: 153.69px;
    top: -25px;
    left: auto;
    right: -190px;
    background-image: url("../images/startIllustrationHE.png");

  }

  .details::before {
    content: '';
    position: absolute;
    width: 166.87px;
    height: 153.69px;
    left: -180px;
    top: -25px;
    background-image: url("../images/startIllustrationEN.png");



  }
}




@media only screen and (max-width: 1190px) {
  .startIllustration {
    display: none
  }

}

.flexDetails {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.details {
  background: rgba(255, 255, 255, 0.98);
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 34px 87px 0px;
  max-width: 744px;
  height: 609px;
  max-height: 609px;
  margin: auto;
  transform: translate(0%, 100px);
  box-shadow: 0px 23px 78px rgba(13, 21, 46, 0.0901961);
  border-radius: 4px;
}

.MultiSteps {
  transform: translate(0%, 106px)
}

.MuiFormControl-root {
  width: 545px;
}

@media only screen and (max-width: 650px) {
  .css-oiyktb-MuiStack-root {
    width: 400px;
  }

  .details {
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
  }


  

  .p_details_mobile {
    margin-bottom: 50px !important;
  }

  .autocomplete-dropdown-container {
    width: 400px
  }

 

}

@media only screen and (max-width: 800px) {

 
  .mobile_details_height {
    background: none;
  }

  .details {
    background: none;
    box-shadow: none;
   
    transform: none;
  }

  .mobile_width_detailofpackage {
    max-width: 516px;
  }

}



h1 {
  text-align: center;
}


[dir="ltr"] #labelLtr {
  transform: translate(214px, -9px) scale(0.75);
}

.form-group {
  margin: 10px;
  border-radius: 5px;
  position: relative;
}

[dir="ltr"] .form-group {
  direction: rtl;
}

[dir="rtl"] .form-group {
  direction: ltr;
}


.form-group>input {
  border: none;
  border: 0;
  line-height: 100%;
}

.displayStepsImg {
  display: flex;
  flex-direction: row-reverse;
}

.p_details_mobile {
  margin-bottom: 70px;
}

.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root {
  left: inherit !important;
  right: 1.75rem !important;
  transform-origin: right !important;
}

.h1_mobile {
  width: 245px;
  height: 71px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 70px;
  /* identical to box height */
  white-space: nowrap;
  text-align: center;
  letter-spacing: -2px;

  color: #0D152E;
}

.flex_header_details {
  display: flex;
  flex-direction: column;
  align-items: center;

}


.p_details_mobile {
  width: 395px;
  height: 29px;

  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
 

  text-align: center;
  letter-spacing: -0.66px;

  color: #81838C;

}

@media only screen and (max-width: 500px) {

  .marginBtn {
    width: -webkit-fill-available !important;
  }

  .muirtl-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid #4F5976 !important;
  }

  .muiltr-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: 1px solid #4F5976 !important;
  }

  .css-oiyktb-MuiStack-root {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 15px;
    width: 336px;
    height: 273px;
  }

  .details {
    width: 375px;
    display: flex;
    flex-direction: column;
    
    padding:0;
    gap: 31px;
  
    height: 462px;
  

  }

  .MuiButton-root {

    margin-top: 50px;

  }

  .mobile_details_height {
    width: 100%;
   


  }


  .flex_header_details {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    width: 100%;
    height: 65px;
    margin-top: 42px;
    padding: 0rem 1rem;
  }

 

  .h1_mobile {
  
    height: 47px;
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 0;

    text-align: end;
    letter-spacing: -1.24px;

    color: #0D152E;
  }



  .mobile_width_detailofpackage {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 0px; */
    gap: 15px;

    width: 100%;
    height: 273px;

  }

  .mobile_details {
    display: flex;
    flex-direction: column;
 
    gap: 31px;
    width: 100%;
  
    height: 462px;
  



  }

  .p_details_mobile {
    text-align: end;
    line-height: 0;
    margin-bottom: 0 !important;
  }



  .flex_date_time {
    margin-bottom: 10px !important;
    flex-direction: column !important;
  }

}




.Arrow {
  margin: 0px 10px;
}

[dir='rtl'] .Arrow {
  transform: scaleX(-1);
}

.flex_date_time {

  margin-top: 60px;
  /* height: 110px; */
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 55px;


}



input[type="datetime-local"]::-webkit-calendar-picker-indicator {
 display: none;
}

/* input[type="datetime-local"]::-webkit-calendar-picker-indicator:hover {
  background: transparent;
  cursor: pointer;
} */

input[type="datetime-local"] input[style*="direction:rtl"] ::-webkit-outer-spin-button, 
input[type="datetime-local"] input[style*="direction:rtl"] ::-webkit-inner-spin-button, 
input[type="datetime-local"] input[style*="direction:rtl"] ::-webkit-clear-button { 
 -webkit-appearance: none !important;
 margin: 0;
}


input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  text-align: right;
  height: auto;
  left: 0;
  position: absolute;
  right: 0px;
  top: 0;
  width: auto;
}

/* .unstyled {
  -webkit-appearance: none;
} */



/* input[type="datetime-local"]::-webkit-datetime-edit-ampm-field {
  display: none;
  } */

 /* input[type="datetime-local"]::-webkit-date-and-time-value {
  margin: 0;
  width: 100%;
  text-align: center;
}
  */
 /* .unstyled::-webkit-inner-spin-button,
.unstyled::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
} */
/* input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {

  -webkit-appearance: none !important;
}  */
